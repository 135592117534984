import request from '@/utils/request'

// 查询专题列表
export const getAllTopic = (params) => {
  return request({
    method: 'GET',
    url: '/v2/api/topic/getAllTopic',
    params: { ...params, status: 1 }
  })
}

// 查询专题详情
export const topicDetail = (id) => {
  return request({
    method: 'GET',
    url: `/v2/api/topic/topic_detail/${id}`
  })
}

export const topicOrTeacherCourse = (params) => {
  return request({
    method: 'GET',
    url: '/v2/course/getAllCourse',
    params: { ...params, status: 1 }
  })
}

// 查询教师详情
export const teacherDetail = (id) => {
  return request({
    method: 'GET',
    url: `/v2/api/teacher/teacher_detail/${id}`
  })
}
